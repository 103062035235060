import React from "react"

const Footer = () => {
  return (
    <footer>
      This site is built with <a href="https://www.gatsbyjs.com/">Gatsby </a>{" "}
      and <a href="https://andybrewer.github.io/mvp/">mvp.css</a>.
    </footer>
  )
}

export default Footer
