import React from "react"
import Body from "../components/body"
import SEO from "../components/seo"

const SocialButton = ({ to, children }) => (
  <a href={to}>
    <strong>{children}</strong>
  </a>
)

const Home = () => {
  return (
    <>
      <SEO title="Home" />
      <Body>
        <header>
          <h1>Hi, I'm Otto!</h1>
          <p>
            I work as a full-stack developer and study acoustics and audio tech
            at Aalto University, Helsinki.
          </p>
        </header>
        <section>
          <SocialButton to="https://github.com/otahontas">Github</SocialButton>
          <SocialButton to="https://www.linkedin.com/in/ottoahoniemi">
            LinkedIn
          </SocialButton>
        </section>
      </Body>
    </>
  )
}

export default Home
